export default class CustomSelectEditor {
  constructor(props) {
    // console.orange('createSelectEditor')

    // console.devlog(props)
    // console.devlog(props.columnInfo)
    var currentFocus
    var hasRelation = props.columnInfo.related
    var itemList = props.columnInfo.editor.options.listItems.value || []
    var sortProperty = props.columnInfo.editor.options.sortProperty
    if (hasRelation) {
      var relatedItemList =
        props.columnInfo.editor.options.relationListItemMap[props.rowKey]
      props.value = props.grid.getValue(props.rowKey, props.columnInfo.name)
    }
    // console.devlog(props)
    var itemText = props.columnInfo.editor.options.itemText || 'Description'
    var itemValue = props.columnInfo.editor.options.itemValue || 'ID'
    // console.devlog(relatedItemList)
    const el = document.createElement('div')
    const inputEl = document.createElement('input')
    inputEl.type = 'text'
    var emptyMatch = itemList.find((i) => i[itemValue] == 0)
    let match = itemList.find((i) => i[itemValue] == props.value)
    if (match) {
      // console.devlog(match)
      inputEl.value = String(match[itemText])
    } else {
      inputEl.value = ''
    }
    el.appendChild(inputEl)
    let self = this
    function generateOptionList(event) {
      if (
        event.inputType != 'historyUndo' &&
        event.inputType != 'historyRedo'
      ) {
        // console.devlog(event, self)
        var a,
          b,
          i,
          val = event.target.value,
          el = event.target,
          offsetTop = event.target.offsetParent.offsetTop,
          offsetHeight =
            event.target.offsetParent.offsetParent.offsetParent.offsetParent
              .offsetHeight
        // console.devlog(
        //   {event},
        //   event.target.offsetParent.offsetTop,
        //   event.target.offsetParent.offsetHeight,
        //   event.target.offsetParent.offsetParent.offsetHeight,
        //   event.target.offsetParent.offsetParent.offsetParent.offsetHeight,
        //   event.target.offsetParent.offsetParent.offsetParent.offsetParent
        //     .offsetHeight,
        //   event.target.offsetParent.offsetParent.offsetParent.offsetParent
        //     .offsetParent.offsetHeight
        // )
        // console.devlog(offsetTop, offsetHeight)
        var availableItems
        if (hasRelation) {
          // console.devlog('use relation list', relatedItemList)
          availableItems = relatedItemList
        } else {
          availableItems = itemList
        }
        if (availableItems.length > 0) {
          var first = availableItems[0]
          if ('IsActive' in first) {
            availableItems = availableItems.filter((x) => x.IsActive)
          }
          if ('DORJurisdiction' in first) {
            availableItems = availableItems.filter(
              (x) => x.DORJurisdiction != null
            )
          }
          if (sortProperty && sortProperty in first) {
            availableItems = availableItems.sort((a, b) => {
              let sort = a[sortProperty] > b[sortProperty] ? 1 : -1
              return sort
            })
          }
        }
        if (
          availableItems.findIndex((x) => x[itemValue] == self.value) == -1 &&
          match
        ) {
          availableItems.unshift(match)
        }
        if (
          availableItems.findIndex((x) => x[itemValue] == 0) == -1 &&
          emptyMatch
        ) {
          availableItems.unshift(emptyMatch)
        }

        /*close any already open lists of autocompleted values*/
        closeAllLists()
        // if (!val) {
        //   return false
        // }
        currentFocus = -1
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement('DIV')
        a.setAttribute('id', el.id + 'autocomplete-list')
        // console.devlog(offsetTop, offsetHeight)
        if (offsetTop + 200 > offsetHeight) {
          a.setAttribute('class', 'autocomplete-items autocomplete-items-up')
        } else {
          a.setAttribute('class', 'autocomplete-items autocomplete-items-down')
        }
        /*append the DIV element as a child of the autocomplete container:*/
        el.parentNode.appendChild(a)
        /*for each item in the array...*/
        // console.devlog(val, !!event.target.value)
        // console.devlog(event.target)
        if (hasRelation) {
        }
        for (i = 0; i < availableItems.length; i++) {
          /*-check if the item starts with the same letters as the text field value:*/
          var isActive =
            !('IsActive' in availableItems[i]) || availableItems[i].IsActive
          var hasDORJurisdiction =
            !('DORJurisdiction' in availableItems[i]) ||
            availableItems[i].DORJurisdiction != null
          var optionText =
            // availableItems[i][itemValue] + ' - ' +
            availableItems[i][itemText]
          if (
            optionText.toUpperCase().includes(val.toUpperCase()) ||
            val.length == 0
          ) {
            /*create a DIV element for each matching element:*/
            b = document.createElement('DIV')
            /*make the matching letters bold:*/
            if (val.length > 0) {
              let matchStart = optionText
                .toUpperCase()
                .indexOf(val.toUpperCase())
              b.innerHTML += optionText.substr(0, matchStart)
              b.innerHTML +=
                '<strong>' +
                optionText.substr(matchStart, val.length) +
                '</strong>'
              b.innerHTML += optionText.substr(matchStart + val.length)
            } else {
              b.innerHTML += optionText
            }
            /*insert a input field that will hold the current array item's value:*/
            b.innerHTML +=
              "<input type='hidden' value='" +
              availableItems[i][itemValue] +
              "' name='" +
              optionText +
              "'>"
            /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener('click', function(e) {
              /*insert the value for the autocomplete text field:*/
              inputEl.value = this.getElementsByTagName('input')[0].name
              self.value = parseInt(this.getElementsByTagName('input')[0].value)
              // console.devlog(e, inputEl, self, this.getElementsByTagName('input')[0])
              /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
              closeAllLists()
            })
            a.appendChild(b)
          }
        }
      }
    }
    inputEl.addEventListener('input', function(evt) {
      generateOptionList(evt)
    })
    inputEl.addEventListener('focus', function(evt) {
      generateOptionList(evt)
    })
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false
      /*start by removing the "active" class on all items:*/
      removeActive(x)
      if (currentFocus >= x.length) currentFocus = 0
      if (currentFocus < 0) currentFocus = x.length - 1
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add('autocomplete-active')
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove('autocomplete-active')
      }
    }
    function closeAllLists(elmnt) {
      /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
      var x = document.getElementsByClassName('autocomplete-items')
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inputEl) {
          x[i].parentNode.removeChild(x[i])
        }
      }
    }
    /*execute a function presses a key on the keyboard:*/
    inputEl.addEventListener('keydown', function(e) {
      var x = document.getElementById(this.id + 'autocomplete-list')
      if (x) x = x.getElementsByTagName('div')
      if (e.key == 'ArrowDown') {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++
        /*and and make the current item more visible:*/
        addActive(x)
      } else if (e.key == 'ArrowUp') {
        //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--
        /*and and make the current item more visible:*/
        addActive(x)
      } else if (e.key == 'Enter' || e.key == 'Tab') {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault()
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click()
        }
      }
    })

    this.el = el
    this.value = props.value
    // console.orange('finishSelectEditor')
  }

  getElement() {
    return this.el
  }

  getValue() {
    // console.devlog(this.value)
    return this.value
  }

  mounted() {
    this.el.firstElementChild.select()
  }
}
