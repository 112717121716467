export default class CustomBoolEditor {
  constructor(props) {
    const el = document.createElement('input')

    el.type = 'checkbox'
    el.checked = props.value

    this.el = el
  }

  getElement() {
    return this.el
  }

  getValue() {
    return this.el.checked
  }

  mounted() {
    this.el.select()
  }
}
