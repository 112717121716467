import {ref, onMounted, watch, computed} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import {useStore} from 'vuex'
import dayjs from 'dayjs'
import CustomSelectEditor from '@classes/ToastEditors/CustomSelectEditor'
import CustomBooleanSelectEditor from '@classes/ToastEditors/CustomBooleanSelectEditor'
import CustomAutofillEditor from '@classes/ToastEditors/CustomAutofillEditor'
import CustomRenderer from '@classes/ToastRenderers/CustomRenderer'
import CustomGridActionRenderer from '@classes/ToastRenderers/CustomGridActionRenderer'
import CustomDateTextEditor from '@classes/ToastEditors/CustomDateTextEditor'
import CustomBoolEditor from '@classes/ToastEditors/CustomBoolEditor'
import useExciseTaxRates from '@composables/useExciseTaxRates'

export default function useExciseExceptionToastHeaders(store, refs) {
  const {getTaxRate} = useExciseTaxRates(store)
  const modified = ref({})
  const selectedProject = computed(() => store.get('project/selectedProject'))
  const selectedProjectStartTime = computed(() =>
    dayjs(selectedProject.value.AuditStart).toDate().getTime()
  )
  const selectedProjectEndTime = computed(() =>
    dayjs(selectedProject.value.AuditEnd).toDate().getTime()
  )
  const references = computed(() =>
    store.get('project/selectedProjectExciseExceptionReferencesObject')
  )
  const vendorCustomer = computed(() =>
    store.get('project/selectedProjectExciseExceptionVendorCustomerObject')
  )

  const exceptionUsers = computed(() =>
    store.get('project/selectedProjectExciseExceptionUsers')
  )

  const stratum = computed(() =>
    store.get('project/selectedProjectExciseStratum')
  )
  const luCounty = computed(() => store.get('luCounty/luCounty'))
  const luCity = computed(() => store.get('luCity/luCity'))
  const luSpecialDistrict = computed(() =>
    store.get('luSpecialDistrict/luSpecialDistrict')
  )
  const luExciseIssues = computed(() =>
    store.get('luExciseIssues/luExciseIssues')
  )
  const luExciseTransactionType = computed(() =>
    store.get('luExciseTransactionType/luExciseTransactionType')
  )

  const luExciseTaxTypes = computed(() =>
    store.get('luExciseTaxTypes/luExciseTaxTypes')
  )
  const availableTaxTypes = computed(() => {
    var projectDetailsID = selectedProject.value.ProjectDetailsID
    return luExciseTaxTypes.value.filter((item) => {
      return item.IsActive && item.ProjectDetailID == projectDetailsID
    })
  })

  //these will watch for changes to the lookup tables and disable/reenable the columns to make sure the text is rendered
  watch(stratum, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'StratumID')
      refs.tuiGrid.invoke('enableColumn', 'StratumID')
    }
  })
  watch(luCounty, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      // refs.tuiGrid.invoke('disableColumn', 'SoldFromCountyID')
      refs.tuiGrid.invoke('disableColumn', 'DeliveredToCountyID')
      // refs.tuiGrid.invoke('enableColumn', 'SoldFromCountyID')
      refs.tuiGrid.invoke('enableColumn', 'DeliveredToCountyID')
    }
  })
  watch(luCity, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'DeliveredToCityID')
      refs.tuiGrid.invoke('enableColumn', 'DeliveredToCityID')
    }
  })
  watch(luSpecialDistrict, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'SpecialDistrictID')
      refs.tuiGrid.invoke('enableColumn', 'SpecialDistrictID')
    }
  })
  watch(luExciseIssues, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'IssueID')
      refs.tuiGrid.invoke('enableColumn', 'IssueID')
    }
  })
  watch(luExciseTransactionType, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'TransactionTypeID')
      refs.tuiGrid.invoke('enableColumn', 'TransactionTypeID')
    }
  })
  watch(availableTaxTypes, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'TaxTypeID')
      refs.tuiGrid.invoke('enableColumn', 'TaxTypeID')
    }
  })
  watch(exceptionUsers, (newValue, oldValue) => {
    if (oldValue.length != newValue.length && refs.tuiGrid) {
      refs.tuiGrid.invoke('disableColumn', 'CreatedByID')
      refs.tuiGrid.invoke('enableColumn', 'CreatedByID')
    }
  })
  const headers = computed(() => {
    return [
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'IsActive',
        formatter: statusFormatter,
        visible: true,
        copyOptions: {
          useFormattedValue: true, // when this option is used, the copy value is concatenated text
        },
        editor: {
          type: CustomBooleanSelectEditor,
          options: {
            listItems: [
              {text: 'Active', value: true},
              {text: 'Inactive', value: false},
            ],
            itemText: 'text',
            itemValue: 'value',
          },
        },
        validation: {
          validatorFn: (value, row, columnName) => {
            return value === true || value === false
          },
        },
        width: 100,
        minWidth: 50,
      },
      {
        text: 'Stratum',
        align: 'left',
        sortable: true,
        value: 'StratumID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: stratum,
            itemText: 'Description',
            itemValue: 'ID',
          },
        },
        validation: {
          required: true,
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            let list = stratum.value
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return matchingIndex > -1
          },
        },
        minWidth: 50,
        width: 200,
      },

      {
        text: 'Transaction Type',
        align: 'left',
        sortable: true,
        value: 'TransactionTypeID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: luExciseTransactionType,
            itemText: 'Description',
            itemValue: 'ID',
          },
        },
        relations: [
          {
            targetNames: ['TaxTypeID'],
            listItems({value}) {
              var list
              let matchingTransactionType = -1
              let transTypeList = luExciseTransactionType.value
              for (var i = 0; i < transTypeList.length; i++) {
                if (transTypeList[i].ID == value) {
                  matchingTransactionType = i
                  break
                }
              }
              if (matchingTransactionType > -1) {
                list = transTypeList[matchingTransactionType].TaxTypes.filter(
                  (item) => {
                    return (
                      item.IsActive &&
                      item.ProjectDetailID ==
                        selectedProject.value.ProjectDetailsID
                    )
                  }
                )
              } else {
                list = availableTaxTypes.value
              }
              return list.map((item) => {
                return {
                  ...item,
                  value: item.ID,
                  text: item.Description,
                }
              })
            },
          },
          {
            targetNames: ['Reference'],
            listItems({row}) {
              let list = references.value
              // console.green(row, list)
              if (row.TransactionTypeID > 0) {
                list = list.filter(
                  (x) => x.TransactionTypeID == row.TransactionTypeID
                )
              }
              if (row.TaxTypeID > 0) {
                list = list.filter((x) => x.TaxTypeID == row.TaxTypeID)
              }
              if (row.IssueID > 0) {
                list = list.filter((x) => x.IssueID == row.IssueID)
              }
              var mappedList = [
                'Sales Invoices',
                'Purchase Invoices',
                'Depreciation Schedule',
                'Reconciliation',
              ].concat(
                list.map((item) => {
                  return item.Reference
                })
              )
              return mappedList
                .filter((v, i, a) => a.indexOf(v) == i)
                .map((item) => {
                  return {
                    value: item,
                    text: item,
                  }
                })
            },
          },
          {
            targetNames: ['VendorCustomer'],
            listItems({row}) {
              let list = vendorCustomer.value
              if (row.TransactionTypeID > 0) {
                list = list.filter(
                  (x) => x.TransactionTypeID == row.TransactionTypeID
                )
              }
              if (row.TaxTypeID > 0) {
                list = list.filter((x) => x.TaxTypeID == row.TaxTypeID)
              }
              if (row.IssueID > 0) {
                list = list.filter((x) => x.IssueID == row.IssueID)
              }
              var mappedList = list.map((item) => {
                return item.VendorCustomer
              })
              return mappedList
                .filter((v, i, a) => a.indexOf(v) == i)
                .map((item) => {
                  return {
                    value: item,
                    text: item,
                  }
                })
            },
          },
        ],
        validation: {
          required: true,
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            let list = luExciseTransactionType.value.filter((x) => x.IsActive)
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return matchingIndex > -1
          },
        },
        minWidth: 50,
        width: 150,
      },
      {
        text: 'Tax Type',
        align: 'left',
        sortable: true,
        value: 'TaxTypeID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          copyOptions: {
            useFormattedValue: false,
          },
          options: {
            listItems: availableTaxTypes,
            itemText: 'TaxType',
            itemValue: 'ID',
          },
        },
        validation: {
          required: true,
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            let list = availableTaxTypes.value
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return matchingIndex > -1
          },
        },
        minWidth: 50,
        width: 125,
      },
      // {
      //   text: 'Sold From County',
      //   align: 'left',
      //   sortable: true,
      //   value: 'SoldFromCountyID',
      //   visible: true,
      //   formatter: selectFormatter,
      //   editor: {
      //     type: CustomSelectEditor,
      //     options: {
      //       listItems: luCounty,
      //       itemText: 'Name',
      //       itemValue: 'ID',
      //     },
      //   },
      //   validation: {
      //     required: true,
      //     dataType: 'number',
      //     validatorFn: (value, row, columnName) => {
      //       let matchingIndex = -1
      //       let list = luCounty.value
      //       for (var i = 0; i < list.length; i++) {
      //         if (list[i].ID == value) {
      //           matchingIndex = i
      //           break
      //         }
      //       }
      //       return matchingIndex > -1
      //     },
      //   },
      //   minWidth: 150,
      // },

      {
        text: 'Invoice Date',
        align: 'left',
        sortable: true,
        value: 'InvoiceDate',
        visible: true,
        formatter: ({value}) => {
          return value ? dayjs(value).format('MM/DD/YYYY') : value
        },
        copyOptions: {
          useFormattedValue: false,
        },
        editor: {
          type: CustomDateTextEditor,
        },
        validation: {
          validatorFn: (value, row, columnName) => {
            let dateTime = dayjs(value).toDate().getTime()
            var low = dateTime < selectedProjectStartTime.value
            var high = dateTime > selectedProjectEndTime.value
            return dateTime == dateTime && !low && !high
          },
        },
        minWidth: 50,
        width: 150,
      },
      {
        text: 'Document Number',
        align: 'left',
        sortable: true,
        value: 'DocumentNumber',
        visible: true,
        editor: 'text',
        validation: {
          required: true,
          validatorFn: (value, row, columnName) => {
            return !!value && value.toString().length < 50
          },
        },
        minWidth: 50,
        width: 200,
      },
      {
        text: 'Vendor Customer',
        align: 'left',
        sortable: true,
        value: 'VendorCustomer',
        formatter: autocompleteFormatter,
        visible: true,
        editor: {
          type: CustomAutofillEditor,
          options: {
            listItems: vendorCustomer,
          },
        },
        validation: {
          required: true,
        },
        minWidth: 50,
        width: 400,
      },
      {
        text: 'Issue',
        align: 'left',
        sortable: true,
        value: 'IssueID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: luExciseIssues,
            itemText: 'Description',
            itemValue: 'ID',
          },
        },
        validation: {
          required: true,
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            let list = luExciseIssues.value
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return matchingIndex > -1
          },
        },
        minWidth: 50,
        width: 500,
      },
      {
        text: 'Description',
        align: 'left',
        sortable: true,
        value: 'Description',
        visible: true,
        editor: 'text',
        validation: {
          required: true,
        },
        minWidth: 50,
        width: 500,
      },
      {
        text: 'Taxable Amount',
        align: 'left',
        sortable: true,
        value: 'TaxableAmount',
        visible: true,
        formatter: moneyFormatter,
        editor: {
          type: 'text',
          options: {
            type: 'number',
          },
        },
        copyOptions: {
          useFormattedValue: false, // when this option is used, the copy value is concatenated text
        },
        validation: {
          required: true,
          validatorFn: (value, row, columnName) => {
            return parseFloat(value) == parseFloat(value)
          },
        },
        minWidth: 50,
        width: 150,
      },
      {
        text: 'Tax Paid',
        align: 'left',
        sortable: true,
        value: 'TaxPaid',
        visible: true,
        formatter: moneyFormatter,
        editor: {
          type: 'text',
          options: {
            type: 'number',
          },
        },
        copyOptions: {
          useFormattedValue: false, // when this option is used, the copy value is concatenated text
        },
        validation: {
          required: true,
          validatorFn: (value, row, columnName) => {
            return parseFloat(value) == parseFloat(value)
          },
        },
        minWidth: 50,
        width: 100,
      },
      {
        text: 'Tax Rate',
        align: 'left',
        sortable: true,
        value: 'taxRate',
        visible: true,
        formatter: taxRateFormatter,
        copyOptions: {
          useFormattedValue: true, // when this option is used, the copy value is concatenated text
        },
        minWidth: 50,
        width: 100,
      },
      {
        text: 'Tax Due',
        align: 'left',
        sortable: true,
        value: 'taxDue',
        visible: true,
        formatter: taxDueFormatter,
        copyOptions: {
          useFormattedValue: true, // when this option is used, the copy value is concatenated text
        },
        minWidth: 50,
        width: 100,
      },
      {
        text: 'Delivered To County',
        align: 'left',
        sortable: true,
        value: 'DeliveredToCountyID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: luCounty,
            itemText: 'Name',
            itemValue: 'ID',
            sortProperty: 'ID',
          },
        },
        relations: [
          {
            targetNames: ['DeliveredToCityID'],
            listItems({value}) {
              var list
              let matchingCountyIndex = -1
              let countyList = luCounty.value
              for (var i = 0; i < countyList.length; i++) {
                if (countyList[i].ID == value) {
                  matchingCountyIndex = i
                  break
                }
              }
              if (matchingCountyIndex > -1) {
                list = countyList[matchingCountyIndex].Cities.filter(
                  (x) => x.IsActive && x.DORJurisdiction != null
                )
              } else {
                list = luCity.value
              }
              return list.map((item) => {
                return {
                  ...item,
                  value: item.ID,
                  text: item.City,
                }
              })
            },
          },
          {
            targetNames: ['SpecialDistrictID'],
            listItems({value}) {
              var list
              let matchingCountyIndex = -1
              let countyList = luCounty.value
              for (var i = 0; i < countyList.length; i++) {
                if (countyList[i].ID == value) {
                  matchingCountyIndex = i
                  break
                }
              }
              let none = luSpecialDistrict.value.find((x) => x.ID == 0)
              if (matchingCountyIndex > -1) {
                list = countyList[matchingCountyIndex].SpecialDistricts.filter(
                  (x) => x.IsActive && x.DORJurisdiction != null
                )
                if (none) {
                  list.unshift(none)
                }
              } else {
                list = [none]
              }
              return list.map((item) => {
                return {
                  ...item,
                  value: item.ID,
                  text: item.SpecialDistrict,
                }
              })
            },
          },
        ],
        validation: {
          required: true,
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            let list = luCounty.value
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return matchingIndex > -1
          },
        },
        minWidth: 50,
        width: 150,
      },
      {
        text: 'Delivered To City',
        align: 'left',
        sortable: true,
        value: 'DeliveredToCityID',
        visible: true,
        formatter: selectFormatter,
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: luCity,
            itemText: 'City',
            itemValue: 'ID',
          },
        },
        // no circular references :(
        // relations: [
        //   {
        //     targetNames: ['DeliveredToCountyID'],
        //     listItems(props) {
        //       var list
        //       var match = luCity.value.find((x) => x.ID == props.value)
        //       if (match) {
        //         list = match.Counties.filter((x) => x.DORJurisdiction != null)
        //       } else {
        //         list = luCounty.value
        //       }
        //       return list
        //     },
        //   },
        // ],
        validation: {
          // dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            var list = []
            let matchingCountyIndex = -1
            let countyList = luCounty.value
            for (var i = 0; i < countyList.length; i++) {
              if (countyList[i].ID == row.DeliveredToCountyID) {
                matchingCountyIndex = i
                break
              }
            }
            if (matchingCountyIndex > -1) {
              list = countyList[matchingCountyIndex].Cities.filter(
                (x) => x.IsActive && x.DORJurisdiction != null
              )
            } else {
              list = luCity.value
            }
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            var valid =
              value == 0 ||
              value == null ||
              value == undefined ||
              matchingIndex > -1
            return valid
          },
        },
        minWidth: 50,
        width: 200,
      },
      {
        text: 'Special District',
        align: 'left',
        sortable: true,
        value: 'SpecialDistrictID',
        visible: true,
        formatter: selectFormatter,
        copyOptions: {
          useFormattedValue: false,
        },
        editor: {
          type: CustomSelectEditor,
          options: {
            listItems: luSpecialDistrict,
            itemText: 'SpecialDistrict',
            itemValue: 'ID',
          },
        },
        validation: {
          dataType: 'number',
          validatorFn: (value, row, columnName) => {
            let matchingIndex = -1
            var list = []
            let matchingCountyIndex = -1
            let countyList = luCounty.value
            for (var i = 0; i < countyList.length; i++) {
              if (countyList[i].ID == row.DeliveredToCountyID) {
                matchingCountyIndex = i
                break
              }
            }
            if (matchingCountyIndex > -1) {
              list = countyList[matchingCountyIndex].SpecialDistricts.filter(
                (x) => x.IsActive && x.DORJurisdiction != null
              )
            }
            for (var i = 0; i < list.length; i++) {
              if (list[i].ID == value) {
                matchingIndex = i
                break
              }
            }
            return (
              value == 0 ||
              value == null ||
              value == undefined ||
              matchingIndex > -1
            )
          },
        },
        minWidth: 50,
        width: 250,
      },
      {
        text: 'Reference',
        align: 'left',
        sortable: true,
        formatter: autocompleteFormatter,
        value: 'Reference',
        visible: true,
        editor: {
          type: CustomAutofillEditor,
          options: {
            listItems: references,
          },
        },
        validation: {
          required: true,
        },
        minWidth: 50,
        width: 250,
      },
      // {
      //   text: 'Sub Description',
      //   align: 'left',
      //   sortable: true,
      //   value: 'SubDescription',
      //   visible: false,
      //   editor: 'text',
      //   minWidth: 200,
      // },

      {
        text: 'Appended',
        align: 'left',
        sortable: true,
        value: 'IsAppended',
        visible: false,
        editor: {
          type: CustomBoolEditor,
        },
        validation: {
          validatorFn: (value, row, columnName) => {
            console.blue('validate appended')
            return value === true || value === false
          },
        },
        minWidth: 50,
        width: 100,
      },
      {
        text: 'Created By',
        align: 'left',
        sortable: true,
        value: 'CreatedByID',
        formatter: createdByFormatter,
        copyOptions: {
          useFormattedValue: true, // when this option is used, the copy value is concatenated text
        },
        visible: true,
        minWidth: 50,
        width: 250,
      },
      {
        text: 'Last Modified By',
        align: 'left',
        sortable: true,
        value: 'ModifiedByID',
        formatter: createdByFormatter,
        copyOptions: {
          useFormattedValue: true, // when this option is used, the copy value is concatenated text
        },
        visible: true,
        minWidth: 50,
        width: 250,
      },
      {
        text: 'ID',
        align: 'left',
        sortable: true,
        visible: true,
        value: 'ID',
        renderer: {
          type: CustomGridActionRenderer,
        },
        minWidth: 50,
        width: 150,
      },
    ]
  })
  function moneyFormatter({value}) {
    if (value != null && value != undefined) {
      return '$' + parseFloat(value).toFixed(2)
  }
  }
  function taxDueFormatter({row, value}) {
    return '$' + parseFloat(value).toFixed(2)
  }

  function taxRateFormatter({value, row}) {
    return parseFloat(value * 100).toFixed(2) + '%'
  }

  function createdByFormatter({value}) {
    let text = ''
    let list = exceptionUsers.value
    for (var i = 0; i < list.length; i++) {
      if (list[i].ID == value) {
        text = list[i].eMail
        break
      }
    }
    return text
  }
  function selectFormatter({row, column, value}) {
    let value1 = parseInt(row[column.name])
    var itemList = column.editor.options.listItems.value || []
    var itemText = column.editor.options.itemText || 'Description'
    var itemValue = column.editor.options.itemValue || 'ID'
    let text = ''
    for (var i = 0; i < itemList.length; i++) {
      if (itemList[i][itemValue] == value1) {
        text =
          // itemList[i].ID + ' - ' +
          itemList[i][itemText]
        break
      }
    }
    return text
  }
  function autocompleteFormatter({row, column, value}) {
    let value1 = row[column.name]
    // console.yellow(value, row, row[column.name], column.editor.options)
    // var itemList = column.editor.options.listItems.value || []
    // var itemText = column.editor.options.itemText || 'Description'
    // var itemValue = column.editor.options.itemValue || 'ID'
    // let text = ''
    // for (var i = 0; i < itemList.length; i++) {
    //   if (itemList[i][itemValue] == value1) {
    //     text =
    //       // itemList[i].ID + ' - ' +
    //       itemList[i][itemText]
    //     break
    //   }
    // }
    return value1
  }
  function statusFormatter({row, column}) {
    // console.magenta(row, column)
    let value = row[column.name]
    if (value === true) {
      return 'Active'
    } else {
      return 'Inactive'
    }
  }

  return {
    headers,
    modified,
    getTaxRate,
  }
}
