import TuiGrid from 'tui-grid'
export default class CustomGridActionRenderer {
  constructor(props) {
    // console.purple('startActionRenderer', props)
    const el = document.createElement('span')
    const btnEl = document.createElement('button')
    const txtEl = document.createElement('span')
    btnEl.type = 'button'
    btnEl.innerHTML = '❌ '
    this.el = el
    el.appendChild(btnEl)
    el.appendChild(txtEl)

    btnEl.addEventListener('click', function(evt) {
      props.grid.removeRow(props.rowKey)
    })
    let item = props.grid.getRow(props.rowKey)
    if (props.formattedValue > 0) {
      if (!item.row) {
        btnEl.style.display = 'none'
      }
      // txtEl.innerHTML = `${props.formattedValue}`
      txtEl.innerHTML = item.row
        ? `row ${item.row} - ${props.formattedValue}`
        : `${props.formattedValue}`
    } else if (props.formattedValue == 0) {
      btnEl.style.display = 'initial'
      txtEl.innerHTML = item.row ? `row ${item.row}` : `New`
    }
    // console.purple('finishActionRenderer')
  }
  getElement() {
    return this.el
  }

  render(props) {
    // console.devlog('render', props)
  }
}
