import dayjs from 'dayjs'
export default class CustomDateTextEditor {
  constructor(props) {
    const el = document.createElement('input')
    console.pink(props)
    el.type = 'text'
    el.value = props.formattedValue
    el.val = props.value

    this.el = el
  }

  getElement() {
    return this.el
  }

  getValue() {
    var date = dayjs(this.el.value)
    if (date.isValid()) {
      return date
        .toDate()
        .toISOString()
        .split('T')[0]
    } else {
      return this.el.value
    }
  }

  mounted() {
    this.el.select()
  }
}
