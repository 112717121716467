export default class CustomAutofillEditor {
  constructor(props) {
    // console.orange('createAutofillEditor')
    var currentFocus
    var hasRelation = props.columnInfo.related
    var itemList = props.columnInfo.editor.options.listItems.value || []
    if (hasRelation) {
      var relatedItemList =
        props.columnInfo.editor.options.relationListItemMap[props.rowKey]
      props.value = props.grid.getValue(props.rowKey, props.columnInfo.name)
    }
    const el = document.createElement('div')
    const inputEl = document.createElement('input')
    inputEl.type = 'text'
    el.appendChild(inputEl)
    inputEl.value = String(props.value)
    function generateOptionList(event) {
      if (
        event.inputType != 'historyUndo' &&
        event.inputType != 'historyRedo'
      ) {
        var a, b, i

        var val = event.target.value
        var el = event.target
        var offsetTop = event.target.offsetParent.offsetTop
        var offsetHeight =
          event.target.offsetParent.offsetParent.offsetParent.offsetParent
            .offsetHeight
        var availableItems
        if (hasRelation) {
          // console.devlog('use relation list', relatedItemList)
          availableItems = relatedItemList
        } else {
          availableItems = itemList
        }

        /*close any already open lists of autocompleted values*/
        closeAllLists()
        // if (!val) {
        //   return false
        // }
        currentFocus = -1
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement('DIV')
        a.setAttribute('id', el.id + 'autocomplete-list')
        // console.devlog(offsetTop, offsetHeight)
        if (offsetTop + 200 > offsetHeight) {
          a.setAttribute('class', 'autocomplete-items autocomplete-items-up')
        } else {
          a.setAttribute('class', 'autocomplete-items autocomplete-items-down')
        }
        /*append the DIV element as a child of the autocomplete container:*/
        el.parentNode.appendChild(a)
        /*for each item in the array...*/
        for (i = 0; i < availableItems.length; i++) {
          /* -check if the item starts with the same letters as the text field value:*/
          var listItem = availableItems[i]
          if (typeof listItem == 'object') {
            listItem = listItem.value
          }
          if (
            listItem.toUpperCase().includes(val.toUpperCase()) ||
            val.length == 0
          ) {
            /*create a DIV element for each matching element:*/
            b = document.createElement('DIV')
            /*make the matching letters bold:*/
            if (val.length > 0) {
              let matchStart = listItem.toUpperCase().indexOf(val.toUpperCase())
              b.innerHTML += listItem.substr(0, matchStart)
              b.innerHTML +=
                '<strong>' +
                listItem.substr(matchStart, val.length) +
                '</strong>'
              b.innerHTML += listItem.substr(matchStart + val.length)
            } else {
              b.innerHTML += listItem
            }
            /*insert a input field that will hold the current array item's value:*/
            b.innerHTML += "<input type='hidden' value='" + listItem + "'>"
            /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener('click', function(e) {
              /*insert the value for the autocomplete text field:*/
              inputEl.value = this.getElementsByTagName('input')[0].value
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists()
            })
            a.appendChild(b)
          }
        }
      } else {
      }
    }
    inputEl.addEventListener('input', function(evt) {
      generateOptionList(evt)
    })
    inputEl.addEventListener('focus', function(evt) {
      generateOptionList(evt)
    })
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false
      /*start by removing the "active" class on all items:*/
      removeActive(x)
      if (currentFocus >= x.length) currentFocus = 0
      if (currentFocus < 0) currentFocus = x.length - 1
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add('autocomplete-active')
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove('autocomplete-active')
      }
    }
    function closeAllLists(elmnt) {
      /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
      var x = document.getElementsByClassName('autocomplete-items')
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inputEl) {
          x[i].parentNode.removeChild(x[i])
        }
      }
    }
    /*execute a function presses a key on the keyboard:*/
    inputEl.addEventListener('keydown', function(e) {
      var x = document.getElementById(this.id + 'autocomplete-list')
      if (x) x = x.getElementsByTagName('div')
      if (e.key == 'ArrowDown') {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++
        /*and and make the current item more visible:*/
        addActive(x)
      } else if (e.key == 'ArrowUp') {
        //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--
        /*and and make the current item more visible:*/
        addActive(x)
      } else if (e.key == 'Enter' || e.key == 'Tab') {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault()
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click()
        }
      }
    })

    this.el = el
    // console.orange('finishAutofillEditor')
  }

  getElement() {
    return this.el
  }

  getValue() {
    // console.devlog('getValue', this.el.firstElementChild)
    return this.el.firstElementChild ? this.el.firstElementChild.value : null
  }

  mounted() {
    this.el.firstElementChild.focus()
  }
}
