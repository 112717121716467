<template>
  <div>
    <v-row>
      <v-col sm="12" md="4" v-if="mode == 'edit'">
        <v-switch v-model="autosave" label="Autosave"></v-switch>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="auto" class="ml-auto">
        <BaseTooltipButton
          class="ma-2"
          :disabled="!undo.length > 0"
          iconColor="primary"
          @click="undoItem"
          icon="mdi-undo"
          >Undo</BaseTooltipButton
        >
        <BaseTooltipButton
          class="ma-2"
          :disabled="!redo.length > 0"
          iconColor="primary"
          @click="redoItem"
          icon="mdi-redo"
          >Redo</BaseTooltipButton
        >
        <v-btn
          v-if="mode == 'add' || mode == 'import'"
          class="ma-2"
          color="primary"
          @click="clearGrid"
          :disabled="unsavedItems && mode != 'import'"
          >Clear</v-btn
        >
        <v-btn
          class="ma-2"
          :disabled="!unsavedItems"
          color="primary"
          @click="revertGrid"
          >Revert</v-btn
        >
        <v-btn
          v-if="mode == 'add' || mode == 'edit'"
          :disabled="!unsavedItems"
          class="ma-2"
          color="primary"
          @click="saveAllChanges"
          >Save Changes</v-btn
        >
        <v-btn
          v-if="mode == 'import'"
          class="ma-2"
          color="primary"
          @click="saveAllChanges"
          >Save Imported</v-btn
        >
      </v-col>
    </v-row>
    <v-card
      :loading="loading || exceptionLoading"
      :disabled="loading || exceptionLoading"
    >
      <Grid
        ref="tuiGrid"
        id="tuiGrid"
        :data="exciseExceptions"
        :columns="tuiHeaders"
        :options="gridProps.options"
        :columnOptions="gridProps.columnOptions"
        @editingFinish="onEditingFinish"
        @editingStart="onEditingStart"
        @afterChange="onAfterChange"
        @beforeChange="onBeforeChange"
        @onGridMounted="onOnGridMounted"
        @onGridUpdated="onOnGridUpdated"
        @onGridBeforeDestroy="onOnGridBeforeDestroy"
        @focusChange="onFocusChange"
        @afterSort="onAfterSort"
        @beforeSort="onBeforeSort"
        @beforeUnsort="onBeforeUnsort"
      ></Grid>
    </v-card>

    <!-- 
          @afterFilter="onAfterFilter"
          @beforeFilter="onBeforeFilter" -->
  </div>
</template>
<script>
import {ref, reactive, watch, computed, inject} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import 'tui-grid/dist/tui-grid.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import {Grid} from '@toast-ui/vue-grid'
import TuiGrid from 'tui-grid'

import ExciseExceptions from '@classes/ExciseExceptions'
import {authMethods, authComputed} from '@state/helpers'

import useExciseExceptionsToastHeaders from '@composables/useExciseExceptionsToastHeaders'
import * as jsonpatch from 'fast-json-patch/index.mjs'

export default {
  //model: {
  //	prop: ''
  //},
  props: {
    items: {
      type: Array,
    },
    additionalItems: {
      type: Array,
    },
    mode: {
      type: String,
    },
  },
  setup(props, context) {
    const store = inject('vuex-store')
    const tuiGrid = ref(null)
    // const refreshGrid = () => {
    //   if (refs.tuiGrid) {
    //     console.devlog('refreshCalled')
    //     //this will refresh (rerender) the entire grid
    //     refs.tuiGrid.invoke('restore')
    //   }
    // }
    const {headers, modified, getTaxRate} = useExciseExceptionsToastHeaders(
      store,
      {tuiGrid: tuiGrid.value}
    )
    const mappedExceptions = computed(() => {
      return props.items.map((item) => {
        var taxRate = 0
        if (!item.TaxRate) {
          if (item.IssueID) {
            taxRate = getTaxRate(
              item.IssueID,
              item.InvoiceDate,
              item.DeliveredToCountyID,
              item.TaxTypeID,
              item.DeliveredToCityID,
              item.SpecialDistrictID
            )
          }
        } else {
          taxRate = item.TaxRate
        }
        var taxDue = item.TaxDue
          ? item.TaxDue
          : item.TaxableAmount * taxRate - item.TaxPaid
        return {
          ...item,
          taxRate,
          taxDue,
        }
      })
    })

    return {
      exciseExceptions: mappedExceptions,
      modified,
      headers,
      getTaxRate,
    } // anything returned here will be available for the rest of the component
  },
  mounted() {
    document.addEventListener('keyup', this.keyupHandler)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyupHandler)
  },
  components: {Grid},
  filters: {},
  data() {
    return {
      rowKey: null,
      gridEvent: {},
      undo: [],
      redo: [],
      allRows: [],
      autosave: true,
      loading: false,
      maxUndo: 50,
    }
  },

  created() {
    if (this.mode == 'add' || this.mode == 'import') {
      this.autosave = false
    }
    this.loadLuCounty()
    this.loadLuCity()
    this.loadLuSpecialDistrict()
    this.loadLuExciseIssues()
    this.loadLuExciseTransactionType()
    this.loadLuExciseTaxTypes()

    if (this.$vuetify.theme.dark) {
      this.setGridTheme('dark')
    } else {
      this.setGridTheme('light')
    }
  },
  beforeDestroy() {
    console.red('toast grid before destroy')
    // this.$refs.tuiGrid.invoke('destroy')
  },
  computed: {
    vuetify() {
      return this.$vuetify
    },
    ...authComputed,
    exceptionLoading: get('exciseExceptions/loading'),
    ...sync('exciseExceptions', [
      'searchSortBy',
      'searchDescending',
      'datasheetUnsavedItems',
    ]),
    unsavedItems() {
      if (
        (this.modified.updatedRows && this.modified.updatedRows.length > 0) ||
        this.mode == 'import'
      ) {
        return true
      } else if (
        this.modified.createdRows &&
        this.modified.createdRows.length > 0
      ) {
        return true
      } else {
        return false
      }
    },
    gridProps() {
      return {
        options: {
          scrollX: true,
          scrollY: true,
          rowHeight: 30,
          bodyHeight: this.$vuetify.breakpoint.height - 240,
          showDummyRows: false,
          usageStatistics: false,
          heightResizable: true,
        },
        columnOptions: {
          resizable: true,
        },
      }
    },
    tuiHeaders() {
      return this.headers
        .filter((h) => h.visible)
        .map((h) => {
          return {
            ...h,
            name: h.value,
            header: h.text,
          }
        })
    },
  },
  methods: {
    ...call('exciseExceptions', [
      'deleteExciseExceptions',
      'saveMultiple',
      'patchExciseExceptions',
      'saveExciseExceptions',
    ]),
    toastMessage: call('messages/toastMessage'),
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuCity: call('luCity/loadLuCity'),
    loadLuSpecialDistrict: call('luSpecialDistrict/loadLuSpecialDistrict'),
    loadLuExciseIssues: call('luExciseIssues/loadLuExciseIssues'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    loadLuExciseTaxTypes: call('luExciseTaxTypes/loadLuExciseTaxTypes'),
    loadReferences: call(
      'project/loadSelectedProjectExciseExceptionReferencesObject'
    ),
    loadVendorCustomer: call(
      'project/loadSelectedProjectExciseExceptionVendorCustomerObject'
    ),
    bounceModified: _.debounce((self) => {
      self.getModifiedRows()
    }, 750),
    getModifiedRows() {
      console.yellow('getModified')
      this.modified = this.$refs.tuiGrid.invoke('getModifiedRows', {
        rowKeyOnly: true,
      })
    },
    saveList(list) {
      if (list.length > 0) {
        console.yellow('disableGrid')
        this.loading = true
        console.yellow('disableGridComplete')
        console.devlog('call saveMultiple method')
        this.saveMultiple(list).then(
          (res) => {
            console.devlog('response received')
            this.$emit('bulkSave', res.data)
          },
          (err) => {
            this.loading = false
          }
        )
      }
    },
    saveAdditionalOnly() {
      this.saveList(this.additionalItems)
    },
    saveAllChanges() {
      console.devlog('save clicked')
      if (
        this.modified.updatedRows &&
        this.modified.createdRows &&
        this.$refs.tuiGrid.invoke('getRowCount') >
          this.modified.createdRows.length + this.modified.updatedRows.length
      ) {
        var invalid = this.$refs.tuiGrid.invoke('validate')
        console.devlog({invalid})
        var emptyItemString = JSON.stringify(
          new ExciseExceptions({
            CreatedByID: this.currentUser.ID,
          }).removeRelated()
        )
        //remove all empty rows
        invalid.forEach((item) => {
          var itemFromGrid = new ExciseExceptions(
            this.$refs.tuiGrid.invoke('getRow', item.rowKey)
          ).removeRelated()
          if (JSON.stringify(itemFromGrid) == emptyItemString) {
            this.$refs.tuiGrid.invoke('removeRow', item.rowKey)
          }
        })
        this.getModifiedRows()
      }
      console.devlog('empty items removed')
      var invalid = this.$refs.tuiGrid.invoke('validate')
      console.devlog('items validated')
      if (invalid.length == 0) {
        this.gridEvent = {}
        this.undo = []
        this.redo = []
        var changes = []
        //on imports the data will only show up as updated if changes were made, so we will try to save all rows
        if (this.mode == 'import') {
          //for importing of excel files get validImported and all items in grid
          let allRows = this.$refs.tuiGrid.invoke('getData')
          changes = this.additionalItems
          allRows.forEach((row) => {
            changes.push(new ExciseExceptions(row).removeRelated())
          })
        } else {
          // convert rows to js class
          console.cyan('getModifiedRows for save')
          var modified = this.$refs.tuiGrid.invoke('getModifiedRows')
          console.cyan('have modified rows')
          modified.createdRows.forEach((row) => {
            changes.push(new ExciseExceptions(row).removeRelated())
          })
          console.cyan('new items added')
          modified.updatedRows.forEach((row) => {
            changes.push(new ExciseExceptions(row).removeRelated())
          })
        }
        console.cyan('modified items added')
        this.saveList(changes)
      } else {
        // toast message to correct validation errors
        var message = [
          `please correct ${invalid.length} invalid items marked with a pink background`,
        ]
        if (invalid.length == 1) {
          message.push(
            'please note that changes take effect when you leave a cell'
          )
        }
        this.toastMessage({
          message,
          color: 'error',
        })
        this.$refs.tuiGrid.invoke(
          'startEditing',
          invalid[0].rowKey,
          invalid[0].errors[0].columnName
        )
      }
    },
    appendRow() {
      this.$refs.tuiGrid.invoke(
        'prependRow',
        new ExciseExceptions({CreatedByID: this.currentUser.ID}).root()
      )
    },
    revertGrid() {
      var modified = this.$refs.tuiGrid.invoke('getModifiedRows')
      var columns = this.$refs.tuiGrid.invoke('getColumns')
      this.$refs.tuiGrid.invoke('restore')
      modified.updatedRows.forEach((row) => {
        columns.forEach((column) => {
          this.$refs.tuiGrid.invoke(
            'removeCellClassName',
            row.rowKey,
            column.name,
            'cell-modified'
          )
        })
      })
      this.modified = {}
      this.undo = []
      this.redo = []
    },
    patchSave(columnName, rowKey) {
      //if you press enter the focused cell will still be the same
      var enterPressed =
        columnName == this.gridEvent.columnName &&
        this.gridEvent.rowKey == rowKey
      var rowChanged = rowKey != this.rowKey
      if (this.autosave && (rowChanged || enterPressed)) {
        //save item individually
        this.getModifiedRows()
        // console.devlog(this.gridEvent.columnName, this.gridEvent.rowKey)
        if (enterPressed) {
          this.gridEvent = {}
        }
        if (this.modified.updatedRows && this.modified.createdRows) {
          var invalid = this.$refs.tuiGrid.invoke('validate')
          console.devlog('invalid', invalid)
          var modified = this.modified

          if (
            invalid.length == 0 &&
            modified.updatedRows &&
            modified.createdRows
          ) {
            // console.devlog(modified)
            if (modified.updatedRows.concat(modified.createdRows).length == 1) {
              var row = new ExciseExceptions(
                this.$refs.tuiGrid.invoke('getRow', rowKey)
              )
              if (row.ID == 0) {
                // console.devlog('edit Finish new item', rowKey)
                console.yellow('disableGrid')
                this.loading = true
                console.yellow('disableGridComplete')
                this.saveExciseExceptions(
                  new ExciseExceptions(row).removeRelated()
                ).then(
                  (res) => {
                    this.$emit('itemAdded', {
                      index: rowKey,
                      item: res.data,
                    })
                    this.undo = []
                    this.redo = []
                  },
                  (err) => {
                    this.loading = false
                  }
                )
              } else {
                var orig = new ExciseExceptions(
                  this.exciseExceptions.find((t) => t.ID == row.ID)
                )
                var diff = jsonpatch.compare(
                  orig.removeRelated(),
                  row.removeRelated()
                )
                if (diff.length > 0) {
                  console.yellow('disableGrid')
                  this.loading = true
                  console.yellow('disableGridComplete')
                  this.patchExciseExceptions({ID: row.ID, patchDoc: diff}).then(
                    (res) => {
                      this.$emit('itemModified', {
                        index: rowKey,
                        item: res.data,
                      })
                      this.undo = []
                      this.redo = []
                    },
                    (err) => {
                      console.green('enableGrid')
                      this.loading = false
                      console.green('enableGridComplete')
                    }
                  )
                }
              }
            } else if (
              modified.updatedRows.concat(modified.createdRows).length > 1
            ) {
              this.autosave = false
            }
          } else if (
            modified.updatedRows.concat(modified.createdRows).length > 1
          ) {
            this.autosave = false
          }
        }
      }
    },
    keyupHandler(event) {
      if (event.ctrlKey && event.code === 'KeyZ') {
        if (this.undo.length > 0) {
          // console.yellow('undo')
          this.undoItem()
        }
      } else if (event.ctrlKey && event.code === 'KeyY') {
        if (this.redo.length > 0) {
          // console.yellow('redo')
          this.redoItem()
        }
      }
    },

    undoItem() {
      let last = this.undo[this.undo.length - 1]
      last.isRedo = false
      last.isUndo = true
      this.redo.push(last)
      this.$refs.tuiGrid.invoke(
        'setValue',
        last.rowKey,
        last.columnName,
        last.prevValue
      )
    },
    redoItem() {
      let last = this.redo[this.redo.length - 1]
      last.isUndo = false
      last.isRedo = true
      this.undo.push(last)
      this.$refs.tuiGrid.invoke(
        'setValue',
        last.rowKey,
        last.columnName,
        last.value
      )
    },
    onEditingFinish(event) {
      // console.devlog('editFinish', event, this.rowKey, this.gridEvent)
      this.patchSave(event.columnName, event.rowKey)
    },
    onEditingStart(event) {
      // console.devlog('editing start', event)
    },
    onAfterChange(event) {
      let {origin, changes} = event
      if (changes.length <= 4000) {
        let trackedChanges = []
        if (changes.length > this.maxUndo) {
          trackedChanges = changes.slice(-this.maxUndo)
        } else {
          trackedChanges = changes
        }
        for (let i = 0; i < trackedChanges.length; i++) {
          let change = trackedChanges[i]
          console.devlog(event, change)
          var isUndo = false
          //it is possible we are doing an undo action if there is only one change
          //but we don't want to track internal updates to the taxRate and taxDue columns
          var uneditable = ['taxRate', 'taxDue']
          if (
            trackedChanges.length == 1 &&
            origin == 'cell' &&
            this.redo.length > 0 &&
            !uneditable.includes(change.columnName)
          ) {
            let lastUndo = this.undo[this.undo.length - 1]
            let lastRedo = this.redo[this.redo.length - 1]
            //an isUndo or isRedo property is set to true if it came from an undo/redo click
            if (lastUndo.isUndo == true) {
              lastRedo.isUndo = false
              this.undo.pop()
              isUndo = true
            }
            if (lastRedo.isRedo == true) {
              lastUndo.isRedo = false
              this.redo.pop()
              isUndo = true
            }
          }
          if (
            change.prevValue != change.value &&
            !isUndo &&
            !uneditable.includes(change.columnName)
          ) {
            this.redo = []
            if (this.undo.length >= this.maxUndo) {
              this.undo.shift()
            }
            this.undo.push(change)
          }
        }
        // compare original item to see if it has been modified
        for (let i = 0; i < changes.length; i++) {
          let change = changes[i]
          var originalItem = this.allRows.find(
            (item) => item.rowKey == change.rowKey
          )
          if (originalItem && originalItem[change.columnName] != change.value) {
            this.$refs.tuiGrid.invoke(
              'addCellClassName',
              change.rowKey,
              change.columnName,
              'cell-modified'
            )
          } else {
            this.$refs.tuiGrid.invoke(
              'removeCellClassName',
              change.rowKey,
              change.columnName,
              'cell-modified'
            )
          }
        }
      } else {
        this.undo = []
        this.redo = []
      }
      this.bounceModified(this)
    },
    onBeforeChange({changes}) {
      //for each change we have the rowKey, columnName, value and nextValue
      let matrix = []
      for (let i = 0; i < changes.length; i++) {
        let change = changes[i]
        if (typeof change.nextValue == 'string' && change.nextValue == '') {
          change.nextValue = null
        }
        switch (change.columnName) {
          case 'IsActive':
          case 'IsAppended':
            if (
              (typeof change.nextValue == 'string' &&
                (change.nextValue.toLowerCase() == 'true' ||
                  change.nextValue.toLowerCase() == 'active')) ||
              change.nextValue == 1 ||
              change.nextValue == true
            ) {
              change.nextValue = true
            } else {
              change.nextValue = false
            }
            break
          case 'TaxPaid':
          case 'TaxableAmount':
            change.nextValue = change.nextValue
              ? parseFloat(change.nextValue.replace('$', ''))
              : change.nextValue
            break
        }
        if (!matrix[change.rowKey]) {
          matrix[change.rowKey] = {}
        }
        matrix[change.rowKey][change.columnName] = change
      }
      if (changes.length <= 4000) {
        for (let i = 0; i < matrix.length; i++) {
          if (matrix[i]) {
            let row = matrix[i]
            if (
              (row.IssueID && row.IssueID.nextValue != row.IssueID.value) ||
              (row.InvoiceDate &&
                row.InvoiceDate.nextValue != row.InvoiceDate.value) ||
              (row.DeliveredToCountyID &&
                row.DeliveredToCountyID.nextValue !=
                  row.DeliveredToCountyID.value) ||
              (row.TaxTypeID &&
                row.TaxTypeID.nextValue != row.TaxTypeID.value) ||
              (row.DeliveredToCityID &&
                row.DeliveredToCityID.nextValue !=
                  row.DeliveredToCityID.value) ||
              (row.SpecialDistrictID &&
                row.SpecialDistrictID.nextValue != row.SpecialDistrictID.value)
            ) {
              console.yellow('get tax rate and tax due')
              var currentrowValues = this.$refs.tuiGrid.invoke('getRow', i)

              var taxRate = this.getTaxRate(
                row.IssueID ? row.IssueID.nextValue : currentrowValues.IssueID,
                row.InvoiceDate
                  ? row.InvoiceDate.nextValue
                  : currentrowValues.InvoiceDate,
                row.DeliveredToCountyID
                  ? row.DeliveredToCountyID.nextValue
                  : currentrowValues.DeliveredToCountyID,
                row.TaxTypeID
                  ? row.TaxTypeID.nextValue
                  : currentrowValues.TaxTypeID,
                row.DeliveredToCityID
                  ? row.DeliveredToCityID.nextValue
                  : currentrowValues.DeliveredToCityID,
                row.SpecialDistrictID
                  ? row.SpecialDistrictID.nextValue
                  : currentrowValues.SpecialDistrictID
              )
              var taxDue =
                (row.TaxableAmount
                  ? row.TaxableAmount.nextValue
                  : currentrowValues.TaxableAmount) *
                  taxRate -
                (row.TaxPaid ? row.TaxPaid.nextValue : currentrowValues.TaxPaid)
              this.$refs.tuiGrid.invoke(
                'setValue',
                i,
                'taxRate',
                taxRate,
                false
              )
              this.$refs.tuiGrid.invoke('setValue', i, 'taxDue', taxDue, false)
            } else if (
              (row.TaxableAmount &&
                row.TaxableAmount.nextValue != row.TaxableAmount.value) ||
              (row.TaxPaid && row.TaxPaid.nextValue != row.TaxPaid.value)
            ) {
              console.yellow('get TaxDue')
              var currentrowValues = this.$refs.tuiGrid.invoke('getRow', i)
              let taxRate = currentrowValues.taxRate
              //if currentrow does not have taxrate, calculate it
              if (currentrowValues.taxRate == 0) {
                taxRate = this.getTaxRate(
                  row.IssueID
                    ? row.IssueID.nextValue
                    : currentrowValues.IssueID,
                  row.InvoiceDate
                    ? row.InvoiceDate.nextValue
                    : currentrowValues.InvoiceDate,
                  row.DeliveredToCountyID
                    ? row.DeliveredToCountyID.nextValue
                    : currentrowValues.DeliveredToCountyID,
                  row.TaxTypeID
                    ? row.TaxTypeID.nextValue
                    : currentrowValues.TaxTypeID,
                  row.DeliveredToCityID
                    ? row.DeliveredToCityID.nextValue
                    : currentrowValues.DeliveredToCityID,
                  row.SpecialDistrictID
                    ? row.SpecialDistrictID.nextValue
                    : currentrowValues.SpecialDistrictID
                )
                this.$refs.tuiGrid.invoke(
                  'setValue',
                  i,
                  'taxRate',
                  taxRate,
                  false
                )
              }
              var taxDue =
                (row.TaxableAmount
                  ? row.TaxableAmount.nextValue
                  : currentrowValues.TaxableAmount) *
                  taxRate -
                (row.TaxPaid ? row.TaxPaid.nextValue : currentrowValues.TaxPaid)
              this.$refs.tuiGrid.invoke('setValue', i, 'taxDue', taxDue, false)
            }
          }
        }
      }
      // console.devlog('before change complete')
    },
    onAfterSort(event) {
      // console.devlog('after sort', event)
    },
    onBeforeUnsort(event) {
      if (this.mode == 'edit') {
        if (event.multiple == true) {
          //we are potentially unsorting on a multiple sort
          var sortList = JSON.parse(JSON.stringify(this.searchSortBy))
          var dirList = JSON.parse(JSON.stringify(this.searchDescending))
          let match = sortList.findIndex((x) => x == event.columnName)
          if (match >= 0) {
            sortList.splice(match, 1)
            dirList.splice(match, 1)
          }
          this.searchSortBy = sortList
          this.searchDescending = dirList
          event.stop()
        } else {
          //reset if not multiple?, can we send an empty array?
          this.searchSortBy = []
          this.searchDescending = []
        }
      } else {
        // reset undo/redo if not edit mode
        this.undo = []
        this.redo = []
      }
    },
    onBeforeSort(event) {
      if (this.mode == 'edit') {
        if (event.multiple == true) {
          //determine whether it is a click or it is setting from the searchGridSort
          if (event.sortState.columns.length == this.searchSortBy.length) {
            //the column list is the same length - we are adding a sort or changing a sort direction
            var sortList = JSON.parse(JSON.stringify(this.searchSortBy))
            var dirList = JSON.parse(JSON.stringify(this.searchDescending))
            //find index and remove if the column is in the list already (remove index from both lists)
            let match = sortList.findIndex((x) => x == event.columnName)
            if (match >= 0) {
              // we are potentially changing the sort direction
              if (dirList[match] == event.ascending) {
                dirList[match] = !event.ascending
              }
            } else {
              // we are adding an item
              sortList.push(event.columnName)
              dirList.push(!event.ascending)
            }
            this.searchSortBy = sortList
            this.searchDescending = dirList
            event.stop()
          } else if (
            event.sortState.columns.length > this.searchSortBy.length
          ) {
            //if the grids list is longer
            var sortList = []
            var dirList = []
            event.sortState.column.forEach((x) => {
              if (x.columnName != 'sortKey') {
                sortList.push(x.columnName)
                dirList.push(!x.ascending)
              }
            })
            let match = sortList.findIndex((x) => x == event.columnName)
            if (match >= 0) {
              // we are changing the sort direction?
              if (dirList[match] == event.ascending) {
                dirList[match] = !event.ascending
              }
            } else {
              // we are adding an item
              sortList.push(event.columnName)
              dirList.push(!event.ascending)
            }
            this.searchSortBy = sortList
            this.searchDescending = dirList
            event.stop()
          } else if (
            this.searchSortBy.length > event.sortState.columns.length
          ) {
            //we are continuing to process the sorts from the searchSortBy
          }
        } else {
          if (
            event.columnName != this.searchSortBy[0] ||
            event.ascending == this.searchDescending[0]
          ) {
            if (Array.isArray(event.columnName)) {
              this.searchSortBy = event.columnName
            } else {
              this.searchSortBy = [event.columnName]
            }
            this.searchDescending = [!event.ascending]
            event.stop()
          }
        }
      } else {
        //reset undo/redo if not in edit mode
        this.undo = []
        this.redo = []
      }
    },
    setGridSort() {
      var currentSort = this.$refs.tuiGrid.invoke('getSortState')
      if (this.searchSortBy.length > 0 && this.searchDescending.length > 0) {
        this.searchSortBy.forEach((sortby, index) => {
          if (index == 0) {
            this.$refs.tuiGrid.invoke(
              'sort',
              this.searchSortBy[0],
              !this.searchDescending[0],
              false
            )
          } else {
            this.$refs.tuiGrid.invoke(
              'sort',
              this.searchSortBy[index],
              !this.searchDescending[index],
              true
            )
          }
        })
      }
    },
    // onAfterFilter(event) {
    //   console.devlog('after filter', event)
    // },
    // onBeforeFilter(event) {
    //   console.devlog('before filter', event)
    // },
    onOnGridMounted(event) {
      // console.devlog('grid mounted', event)
    },
    onOnGridUpdated(event) {
      console.green('enableGrid')
      this.loading = false
      console.green('enableGridComplete')
      //enable grid
      // console.devlog('grid updated', event)
    },
    onOnGridBeforeDestroy(event) {
      // console.devlog('grid before destroy', event)
    },
    onFocusChange(event) {
      this.gridEvent = {rowKey: event.rowKey, columnName: event.columnName}
      this.rowKey = event.rowKey
      let unEditableColumns = [
        'TaxRate',
        'TaxPaid',
        'CreatedByID',
        'ModifiedByID',
        'ID',
      ]
      if (
        event.prevRowKey != null &&
        event.prevRowKey != event.rowKey &&
        unEditableColumns.includes(event.prevColumnName)
      ) {
        this.patchSave(event.prevColumnName, event.prevRowKey)
      }
      // console.devlog('focus change', event, this.gridEvent, this.rowKey)
    },
    clearGrid() {
      this.$emit('clearGrid')
      this.datasheetUnsavedItems = false
      this.gridEvent = {}
      this.undo = []
      this.redo = []
      this.modified = {}
    },

    getData() {
      return this.$refs.tuiGrid.invoke('getData')
    },
    setGridTheme(mode) {
      if (mode == 'dark') {
        TuiGrid.applyTheme('clean', {
          selection: {
            background: '#0099ff',
            border: '#015883',
          },
          heightResizeHandle: {
            border: '#1E1E1E',
            background: '#252525',
          },
          pagination: {
            border: 'transparent',
            background: 'transparent',
          },
          scrollbar: {
            border: '#1E1E1E',
            background: '#252525',
            emptySpace: '#1E1E1E',
            thumb: '#616161',
            active: '#616161',
          },
          outline: {
            border: '#444',
            showVerticalBorder: false,
          },
          frozenBorder: {
            border: '#444',
          },
          area: {
            header: {
              background: '#111',
              border: '#444',
            },
            body: {
              background: '#252525',
            },
            summary: {
              background: '#252525',
              border: '#444',
            },
          },
          cell: {
            normal: {
              background: '#222',
              border: '#444',
              text: '#ccc',
              showVerticalBorder: false,
              showHorizontalBorder: false,
            },
            header: {
              background: '#252525',
              border: '#1E1E1E',
              text: '#ddd',
              showVerticalBorder: true,
              showHorizontalBorder: true,
            },
            selectedHeader: {
              background: '#212d3a',
            },
            rowHeader: {
              background: '#252525',
              border: '#444',
              text: '#ccc',
              showVerticalBorder: false,
              showHorizontalBorder: false,
            },
            selectedRowHeader: {
              background: '#212d3a',
            },
            summary: {
              background: '#252525',
              border: '#444',
              text: '#ccc',
              showVerticalBorder: false,
              showHorizontalBorder: false,
            },
            focused: {
              // background: '#252525',
              border: '#02699d',
            },
            focusedInactive: {
              border: '#555',
            },
            required: {
              background: '#252525',
              text: '#ccc',
            },
            editable: {
              background: '#252525',
              text: '#ccc',
            },
            disabled: {
              background: '#060606',
              text: '#ccc',
            },
            invalid: {
              background: '#7c0303',
              text: '#ccc',
            },
            currentRow: {
              // background: '#fbfbfb',
              // text: '#ccc',
            },
            evenRow: {
              // background: '#fbfbfb',
              // text: '#ccc',
            },
            oddRow: {
              // background: '#fbfbfb',
              // text: '#ccc',
            },
            dummy: {
              background: '#252525',
            },
          },
          row: {
            // even: {
            //   background: '#000',
            //   text: '#ccc',
            // },
            // odd: {
            //   background: '#000',
            //   text: '#ccc',
            // },
            // dummy: {
            //   background: '#000',
            // },
            hover: {
              background: '#616161',
            },
          },
        })
      } else {
        TuiGrid.applyTheme('clean', {
          row: {
            hover: {
              background: '#F2F4F5',
            },
          },
          cell: {
            header: {
              background: '#fff',
              showVerticalBorder: false,
            },
            focused: {
              border: '#78909C',
            },
            required: {
              background: '#FFF',
            },
          },
        })
      }
    },
  },

  watch: {
    '$vuetify.theme.dark': {
      handler(nval, oval) {
        if (nval) {
          this.setGridTheme('dark')
        } else {
          this.setGridTheme('light')
        }
      },
    },
    exciseExceptions(nval, oval) {
      //data has changed
      // console.devlog('data has changed', nval.length)
      this.loading = true
      this.modified = {}
      this.undo = []
      this.redo = []
      if (this.$refs.tuiGrid) {
        this.$refs.tuiGrid.invoke('resetData', nval)
        this.$refs.tuiGrid.invoke('resetOriginData')
        this.setGridSort()
        console.lastTime.magenta = null
        console.magenta('set grid height')
        this.$refs.tuiGrid.invoke('setBodyHeight', 'auto')
        console.magenta('reset grid height')
        this.$refs.tuiGrid.invoke(
          'setBodyHeight',
          this.$vuetify.breakpoint.height - 240
        )
        console.magenta('reset grid height complete')

        //save rows so that we can lookup original data by rowkey
        let allRows = this.$refs.tuiGrid.invoke('getData')
        this.allRows = allRows
        if (
          this.gridEvent &&
          this.gridEvent.rowKey >= 0 &&
          this.gridEvent.columnName
        ) {
          this.$refs.tuiGrid.invoke(
            'startEditing',
            this.gridEvent.rowKey,
            this.gridEvent.columnName
          )
        }
      }
    },
    '$vuetify.breakpoint.height': {
      handler(nval, oval) {
        this.$refs.tuiGrid.invoke('setHeight', nval - 240)
      },
    },
    unsavedItems(nval, oval) {
      if (nval != oval) {
        this.datasheetUnsavedItems = nval
      }
    },
    // searchSortBy(nval, oval) {
    //   console.cyan(nval, oval)
    //   this.setGridSort(this.searchSortBy, this.searchDescending)
    // },
    // searchDescending(nval, oval) {
    //   console.cyan(nval, oval)
    //   this.setGridSort(this.searchSortBy, this.searchDescending)
    // },
  },
}
</script>
<style>
.tui-grid-cell-header {
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px !important;
}

.tui-grid-container {
  font-family: 'Roboto' sans-serif !important;
  font-size: 14px !important;
}
.tui-grid-cell {
  padding: 0 16px !important;
}
.tui-grid-cell-content-editor {
  background: var(--v-background-base);
  color: var(--v-text-base);
}
.tui-grid-cell-content-editor input {
  color: var(--v-text-base);
}
.tui-grid-cell-content-editor input[type='text'],
.tui-grid-cell-content-editor input[type='password'] {
  border: none;
}
.tui-grid-cell-content-editor input[type='checkbox'] {
  margin-left: calc(50% - 1em);
}
.cell-modified:not(.tui-grid-cell-invalid) {
  background-color: var(--v-modified-base) !important;
}
.cell-warning::after {
  color: #ff7b00 !important;
  content: '⚠';
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.cell-warning:hover::after {
  color: #ff7b00 !important;
  content: attr(data-warning);
  position: absolute;
  height: 25px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  background-color: #ffe7cc;
  padding-left: 5px;
  color: #000000;
  z-index: 10;
}

.tui-grid-layer-editing {
  border-width: 2px;
}
/* Autocomplete styling */
* {
  box-sizing: border-box;
}
body {
  font: 16px Arial;
}
.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
  bottom: 100%;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid var(--v-border-base);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  color: black;
  /*position the autocomplete items to be the same width as the container:*/
  left: 0;
  right: 0;
}
.autocomplete-items-up {
  bottom: 100%;
}
.autocomplete-items-down {
  top: 100%;
}
.autocomplete-items div {
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
  background-color: var(--v-background-base);
  color: var(--v-text-base);
  border-bottom: 1px solid var(--v-border-base);
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: var(--v-hover-base);
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: var(--v-primary-base) !important;
  color: #ffffff !important;
}
.tui-grid-border-line {
  position: absolute;
  z-index: 5;
}
/* apply default background color from theme */
.tui-grid-layer-state {
  background: transparent !important;
}
.tui-grid-cell-content input[type='number'] {
  width: 100%;
  padding: 6px 7px;
  /* border: solid 1px #ddd; */
}
.tui-grid-container input[type='number'] {
  outline: none;
  box-sizing: border-box;
  line-height: normal;
}
.tui-grid-container input::-webkit-outer-spin-button,
.tui-grid-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tui-grid-container input[type='number'] {
  -moz-appearance: textfield;
}
</style>
